<template>
  <div class='main-text-about row col-lg-8 col-xs-8 justify-center'>
    <div class='row col-lg-12 col-xs-12 justify-center'>
      <p class='header-text'>A propos</p>
    </div>
    <div class='story-text-about row col-lg-6 col-xs-10 justify-center'>
      <p> 
        Salut ! ✌️
        <br>
        <br>
        Je m'appelle Cédric Carrière, je suis ingénieur QA le jour, et passionné d'informatique, de jeux vidéos, d'automobile et amateur de photographie, le reste du temps.
	<br>
	<br>
	Je posterai ici donc des moments, paysages, environnements, qui ont attiré mon attention, et que j'aurais capturé, à ma manière 😉
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aboutPage',

  methods: {
  }

}
</script>

<style>
template {
 overflow: 'hidden'
}

.main-text-about{
  margin-top: 5%;
}

.story-text-about{
  text-align: center;
}
</style>
