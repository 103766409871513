<template>
  <div class='row col-lg-12 col-xs-12 justify-center'>
    <div class='row col-lg-6 col-xs-6 justify-center'>
      <q-img class='nature'
        :src="require('../assets/pictures/nature-2.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-6 col-xs-6 justify-center'>
      <q-img class='paysage'
        :src="require('../assets/pictures/nature-6.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-12 col-xs-12 justify-center'>
      <q-img class='paysage'
        :src="require('../assets/pictures/paysage-2.jpg')"
        spinner-color="black"
      />
    </div>

    <div class='row col-lg-4 col-xs-6 justify-center'>
      <q-img class='volleyball'
        :src="require('../assets/pictures/paysage-3.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-4 col-xs-6 justify-center'>
      <q-img class='volleyball'
        :src="require('../assets/pictures/nature-3.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-4 col-xs-12 justify-center'>
      <q-img class='volleyball'
        :src="require('../assets/pictures/nature-4.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-6 col-xs-6 justify-center'>
      <q-img class='city'
        :src="require('../assets/pictures/street-1.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-6 col-xs-6 justify-center'>
      <q-img class='city'
        :src="require('../assets/pictures/nature-5.jpg')"
        spinner-color="black"
      />
    </div>
    <div class='row col-lg-12 col-xs-12 justify-center'>
      <q-img class='city'
        :src="require('../assets/pictures/paysage-1.jpg')"
        spinner-color="black"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'portfolio',

  beforeMount(){
    document.body.style.overflow = 'unset'
  }
}
</script>

